import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { usePagination } from '@/commons/hooks/pagination/use-pagination'
import { HandlerVariant } from '@/commons/utils/erros/variant'
import { Icons } from '@/commons/utils/literals/icons'
import { Literals } from '@/commons/utils/literals/literals'
import { PaginationStateKeys } from '@/commons/stores/pagination/pagination'

export default function useClientesList() {
  // Use toast
  const toast = useToast()

  const refItemListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'razao_social',
      label: 'Razão Social / Nome ',
      sortable: true,
    },
    {
      key: 'cnpj',
      label: 'CNPJ/CPF',
    },
    { key: 'endereco' },
    { key: 'empresas' },
    {
      key: 'Ações',
      class: 'text-center',
    },
  ]

  const {
    perPage,
    totalItens,
    currentPage,
    perPageOptions,
    searchQuery,
    isSortDirDesc,
    sortBy,
  } = usePagination({
    id: PaginationStateKeys.GRID_CLIENTES,
    sortBy: 'razao_social',
  })

  const dataMeta = computed(() => {
    currentPage.value - 1

    const localItemsCount = refItemListTable.value
      ? refItemListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const refetchData = () => {
    refItemListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData()
  })

  const fetchClientes = (ctx, callback) => {
    store
      .dispatch('app-clientes/fetchClientes', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        callback(response.data)
        totalItens.value = response.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar os usuários',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const update = (ctx, callback) => {
    store
      .dispatch('app-clientes/fetchClientes', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        callback(response.data)
        totalItens.value = response.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: Literals.FETCH_GRID_USUARIOS_ERROR,
            icon: Icons.ALERT_TRIANGLE_ICON,
            variant: HandlerVariant.DANGER,
          },
        })
      })
  }
  const deleteCliente = (id) => {
    store.dispatch('app-clientes/deleteCliente', id).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Cliente excluído com sucesso',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      refetchData()
    })
  }

  return {
    fetchClientes,
    update,
    deleteCliente,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refItemListTable,
    refetchData,
  }
}
